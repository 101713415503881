export default {
    state: {
        video: {},
        modalVideo: {
          src: null,
          index: null,
          watched: false,
          slug: null,
        }
    },
    getters: {
      getVideo: (s) => s.video,
      modalVideo: (s) => s.modalVideo,
    },
    mutations: {
      setVideo(s, data) {
        s.video = data;
      },
      setModalVideo(s, {src, ind, slug}) {
        s.modalVideo = {
          src: src,
          index: ind,
          slug: slug,
          watched: false
        }
      },
      setModalVideoWatched(s, status) {
        s.modalVideo.watched = status
      }
    },
    actions: {
      async getVideoBySlug(ctx, slug) {
        return this.$axios({
          method: 'GET',
          url: `v1/videos/${slug}/`,
          data: { slug: slug },
        })
          .then((result) => {
            // ctx.commit("setVideo", result.data)
            return result.data
          })
          .catch((error) => {
            console.error(error)
            return false
          })
      },
      async sendVideoView(ctx, slug) {
        ctx.commit('setModalVideoWatched', true)
        return this.$axios({
          method: 'POST',
          url: `v1/videos/${slug}/view/`,
        })
      },
      openVideoModal(ctx, video) {
        ctx.commit('setModalVideo', {src: video.src, ind: 0, slug: video.slug})
      },
      closeVideoModal(ctx) {
        ctx.commit('setModalVideo', {src: null, ind: null})
      },
     },
  };
  